<template>
  <app-nav @onload="onLoad"/>
  <div v-if="loading" class="loading"></div>
  <div v-else class="scroll">
    <div class="app-nav">
      <nav>
        <a @click="setList('member')">会員一覧</a>
        | <a @click="setList('ticket')">チケット一覧</a>
      </nav>
    </div>
    <member-list v-if="list === 'member'"/>
    <ticket-list v-else-if="list === 'ticket'"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import MemberList from '@/components/MemberList.vue'
import TicketList from '@/components/TicketList.vue'

export default {
  name: 'Admin',
  components: {
    AppNav,
    MemberList,
    TicketList,
  },
  data() {
		return {
      loading: true,
      list: 'ticket'
		}
	},
  methods: {
    onLoad(user) {
      if (user.id) {
        this.loading = false;
      }
    },
    setList(a) {
      this.list = a;
    }
  }
}
</script>


<style scoped>
div.app-nav .user {
  text-align: right;
  margin: 0 4pt;
}
div.app-nav nav {
  padding: 4pt;
}
div.app-nav nav a {
  cursor: pointer;
}
</style>
