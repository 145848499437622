<template>
<div class="ticket-list">
<h2>チケット一覧</h2>
<div class="scroll">
<div>全{{ count }}件</div>
<table class="list">
  <thead>
    <tr>
    <th>会員</th>
    <th>発行日時</th>
    <th>価格</th>
    <th>期限</th>
    <th>決済情報</th>
    </tr>
  </thead>
  <tbody v-for="item in items" :key="item.id">
    <tr>
    <td>{{ item.mail }}</td>
    <td>{{ item.issued }}</td>
    <td>{{ item.price }}</td>
    <td>{{ item.expire }}</td>
    <td>{{ item.info }}</td>
    </tr>
  </tbody>
</table>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'TicketList',
  data() {
		return {
			items: [],
      count: 0
		}
	},
  mounted() {
    this.load();
  },
  methods: {
    load() {
      axios.post(config.API+'ticket.php', {
        action: 'get'
      },
      {
        withCredentials: true
      }).then(res => {
        if(res.data.status == 'success') {
          this.items = res.data.items;
          this.count = res.data.count;
        }
      });
    },
  }
}
</script>

<style scoped>
.ticket-list {
  padding: 4pt;
}
</style>
