<template>
<div class="member-list">
<h2>会員一覧</h2>
<div class="scroll">
<div>全{{ count }}件</div>
<table class="list">
  <thead>
    <tr>
    <th>ID</th>
    <th>メール</th>
    <th>名前</th>
    <th>登録日時</th>
    <th>ログイン日時</th>
    </tr>
  </thead>
  <tbody v-for="item in items" :key="item.id">
    <tr>
    <td>{{ item.id }}</td>
    <td>{{ item.mail }}</td>
    <td>{{ item.name }}</td>
    <td>{{ item.added }}</td>
    <td>{{ item.loggedin }}</td>
    </tr>
  </tbody>
</table>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'MemberList',
  data() {
		return {
			items: [],
      count: 0
		}
	},
  mounted() {
    this.load();
  },
  methods: {
    load() {
      axios.post(config.API+'member.php', {
        action: 'get'
      },
      {
        withCredentials: true
      }).then(res => {
        if(res.data.status == 'success') {
          this.items = res.data.items;
          this.count = res.data.count;
        }
      });
    },
  }
}
</script>

<style scoped>
.member-list {
  padding: 4pt;
}
</style>
